<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { calendarEvents, categories } from "./data-calendar";
import API from "@/api.js";

import moment from 'moment';
import { nanoid } from 'nanoid'

/**
 * Calendar component
 */
export default {
  page: {
    title: "Appuntamenti",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { FullCalendar, Layout, PageHeader },
  data() {
    return {
      title: "Appuntamenti",
      items: [
        {
          text: "Appuntamenti",
        },
        {
          text: "Scheduling",
          active: true,
        },
      ],
      appointments:undefined,
      appprova:[
        
        {
            title: "prova2",
            start:  new Date().setDate(new Date().getDate() + 1),
            end:  new Date().setDate(new Date().getDate() + 1),
        },
        {
            title: "prova4",
            patient: 9,
            start: new Date().setDate(new Date().getDate() -3),
        },
       
            
      ],
          calendarEvents: calendarEvents,
          calendarOptions: {
            headerToolbar: {
              left: "prev,next today",
              center: "title",
              right: "timeGridWeek,timeGridDay,listWeek",
            },
            plugins: [
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              bootstrapPlugin,
              listPlugin,
            ],
            initialView: "timeGridWeek",
            themeSystem: "bootstrap",
            initialEvents: this.appoovra,
            editable: true,
            droppable: true,
            events:this.appprova,
            eventResizableFromStart: true,
            dateClick: this.dateClicked,
            eventClick: this.editEvent,
            eventsSet: this.handleEvents,
            weekends: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: true,
          },
          patientList:[],
          currentEvents: [],
          showModal: false,
          eventModal: false,
          categories: categories,//elimina
          submitted: false,
          submit: false,
          newEventData: {},
          edit: {},
          deleteId: {},

          event: {
            patient: {},
            date:"",
            timeStart:"",
            timeEnd:"",
            note: "",
          },
          editevent: {
            editPatient: {},
            editDate:"",
            editTimeStart:"",
            editTimeEnd:"",
            editNote: "",
          },
        };
      },
      validations: {
        event: {
          patient: { required },
          date: { required },
          timeStart: { required },
          timeEnd: { required },
          
        },
  },

  mounted() {
    // Set the initial number of items

    this.axios.get(API.getPatientsLite.path()).then(response => {
      this.patientList=response.data;
      console.log(response)

    }).catch(error=>{
      console.log(error)

    });

    this.axios.get(API.getAppointments.path()).then(response => {
      this.appointments=response.data;
      console.log(response)
      this.calendarKey = nanoid(20);

    }).catch(error=>{
      console.log(error)

    });
    
  },

    methods: {
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const title = this.event.patient;
        let calendarApi = this.newEventData.view.calendar;

        this.currentEvents = calendarApi.addEvent({
          id: this.newEventData.length + 1,
          title,
          start: new Date(this.event.date+"T"+this.event.timeStart),
          end: new Date(this.event.date+"T"+this.event.timeEnd),
        });
        this.successmsg();
        this.showModal = false;
        this.newEventData = {};
      }
      this.submitted = false;
      this.event = {};
    },
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.event = {};
    },
    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    editSubmit(e) {
      this.submit = true;
      const editTitle = this.editevent.editTitle;
      const editcategory = this.editevent.editcategory;

      this.edit.setProp("title", editTitle);
      this.edit.setProp("classNames", editcategory);
      this.successmsg();
      this.eventModal = false;
    },

    /**
     * Delete event
     */
    deleteEvent() {
      this.edit.remove();
      this.eventModal = false;
    },
    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.event.date=moment(String(info.dateStr)).format('YYYY-MM-DD');
      this.event.timeStart=moment(String(info.dateStr)).format('hh:ss');
      this.event.timeEnd=moment(String(info.dateStr)).format('hh:ss');
      this.showModal = true;
      console.log( new Date('1995-12-17T03:24:00') )
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      this.edit = info.event;
      this.editevent.editPatient = this.calendarEvents[1].patient;
      this.editevent.editDate = this.edit.date;
      this.editevent.editTimeStart = this.edit.timeStart;
      this.editevent.editTimeEnd = this.edit.timeEnd;
      this.editevent.editNote = this.edit.note;
      this.eventModal = true;
      console.log(this.editevent)
    },

    closeModal() {
      this.eventModal = false;
    },

    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent();
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been saved",
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
   <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <FullCalendar
                :key="calendarKey"
                v-if="appointments"
                ref="fullCalendar"
                :options="calendarOptions"
              ></FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="showModal"
      title="Nuovo Appuntamento"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
    >
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label class="control-label form-label">Cliente</label>
              <select
                v-model="event.patient"
                class="form-control"
                name="patient"
                :class="{ 'is-invalid': submitted && $v.event.category.errors }"
              >
                <option
                  v-for="patient in patientList"
                  :key="patient.id"
                  :value="`${patient.id}`"
                >
                  {{ patient.name }}  {{patient.lastname}}
                </option>
              </select>
              <div
                v-if="submitted && !$v.event.category.required"
                class="invalid-feedback"
              >
                Selezionare un Cliente.
              </div>
            </div>
          </div>
          
          <div class="col-12">
            <div class="mb-3">
              <label for="date" class="form-label">Data</label>
              <input
                id="date"
                v-model="event.date"
                type="date"
                class="form-control"
                placeholder="Selezionare Cliente"
                :class="{ 'is-invalid': submitted && $v.event.title.$error }"
              />
              <div
                v-if="submitted && !$v.event.title.required"
                class="invalid-feedback"
              >
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="timeStart" class="form-label">Ora inizio</label>
              <input
                id="timeStart"
                v-model="event.timeStart"
                type="time"
                class="form-control"
                placeholder="Selezionare Cliente"
                :class="{ 'is-invalid': submitted && $v.event.title.$error }"
              />
              <div
                v-if="submitted && !$v.event.title.required"
                class="invalid-feedback"
              >
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="timeEnd" class="form-label">Ora fine</label>
              <input
                id="timeEnd"
                v-model="event.timeEnd"
                type="time"
                class="form-control"
                placeholder="Selezionare Cliente"
                :class="{ 'is-invalid': submitted && $v.event.title.$error }"
              />
              <div
                v-if="submitted && !$v.event.title.required"
                class="invalid-feedback"
              >
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="note" class="form-label">Note</label>
              <input
                id="note"
                v-model="event.note"
                type="text"
                class="form-control"
                placeholder="Inerire una nota"
              />
            </div>
          </div>
        </div>

        <div class="text-end pt-5 mt-3">
          <b-button variant="light" @click="hideModal">Annulla</b-button>
          <b-button type="submit" variant="success" class="ms-1"
            >Aggiungi</b-button
          >
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      v-model="eventModal"
      title="Edit Event"
      title-class="text-black font-18"
      hide-footer
      body-class="p-3"
    >
      <div slot="modal-title">Varia Appuntamento</div>
      <form @submit.prevent="editSubmit">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="name" class="form-label">Cliente</label>
              <input
                id="name"
                v-model="editevent.editPatient"
                type="text"
                class="form-control"
                placeholder="Insert Event name"
              />
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label for="date" class="form-label">Data</label>
                <input
                  id="date"
                  v-model="editevent.editDate"
                  type="date"
                  class="form-control"
                  placeholder="Selezionare Cliente"
                  :class="{ 'is-invalid': submitted && $v.editevent.editPatient.$error }"
                />
                <div
                  v-if="submitted && !$v.editevent.editPatient.required"
                  class="invalid-feedback"
                >
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="timeStart" class="form-label">Ora inizio</label>
                <input
                  id="timeStart"
                  v-model="editevent.editTimeStart"
                  type="time"
                  class="form-control"
                  placeholder="Selezionare Cliente"
                  :class="{ 'is-invalid': submitted && $v.editevent.editTimeStart.$error }"
                />
                <div
                  v-if="submitted && !$v.editevent.editTimeStart.required"
                  class="invalid-feedback"
                >
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="timeEnd" class="form-label">Ora fine</label>
                <input
                  id="timeEnd"
                  v-model="editevent.editTimeEnd"
                  type="time"
                  class="form-control"
                  placeholder="Selezionare Cliente"
                  :class="{ 'is-invalid': submitted && $v.editevent.editTimeEnd.$error }"
                />
                <div
                  v-if="submitted && !$v.editevent.editTimeEnd.required"
                  class="invalid-feedback"
                >
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label for="note" class="form-label">Note</label>
                <input
                  id="note"
                  v-model="editevent.editNote"
                  type="text"
                  class="form-control"
                  placeholder="Inerire una nota"
                />
              </div>
            </div>
         </div>          
        </div>
        <div class="text-end p-3">
          <b-button variant="light" @click="closeModal">Close</b-button>
          <b-button class="ms-1" variant="danger" @click="confirm"
            >Elimina</b-button
          >
          <b-button class="ms-1" variant="success" @click="editSubmit"
            >Salva</b-button
          >
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
