
const calendarEvents = [
    {
        id:1,
        title: "stringa",
        patient: "9",
        start:  "2022-10-11T10:54:45Z",
        end:  "2022-10-11T11:54:45Z",
        note: 'Primo appuntamento',
    },
    
    {
        id:2,
        patient: 9,
        title: "prova2",
        start:  new Date().setDate(new Date().getDate() + 1),
        end:  new Date().setDate(new Date().getDate() + 1),
        note: 'Primo appuntamento',
    },
    {
        id:3,
        title: "prova4",
        patient: 9,
        start: new Date().setDate(new Date().getDate() -3),
        end: new Date().setDate(new Date().getDate() -3),
        note: 'Primo appuntamento',
    },
    {
        id:4,
        title: "prova5",
        patient: 9,
        start: new Date().setDate(new Date().getDate() -4),
        end: new Date().setDate(new Date().getDate() -4),
        note: 'Primo appuntamento',
    },
    
];

export {calendarEvents };
